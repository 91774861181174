/* eslint-disable react/forbid-prop-types */
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DataGridNoRowsOverlay from '../dataGrids/DataGridNoRowsOverlay';
import RefreshIconButton from '../buttons/RefreshIconButton';
import FingoStack from '../stacks/FingoStack';
import BasicHeader from '../headers/BasicHeader';

import {
  buildOrderByString,
  mapFiltersToVariables,
} from '../../helpers/data-grid';

const FingoMainViewV2 = ({
  id,
  apolloQuery: { query, variables, orderBy,
    onCompleted, onError },
  slots,
  slotProps: { headerProps, summaryProps, actionsProps, bodyProps, tableProps },
}) => {
  const mergedSlots = {
    header: BasicHeader,
    summary: null,
    actions: null,
    body: null,
    table: DataGrid,
    ...slots,
  };
  const {
    header: Header,
    summary: Summary,
    actions: Actions,
    body: Body,
    table: Table,
  } = mergedSlots;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState(orderBy ? [orderBy] : []);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [headerFilters, setHeaderFilters] = useState({});
  const [actionsFilters, setActionsFilters] = useState({});

  const orderByStr = useMemo(() => buildOrderByString(sortModel), [sortModel]);
  const filterVariables = useMemo(
    () => mapFiltersToVariables(filterModel),
    [filterModel],
  );

  const { data, loading, refetch } = useQuery(query, {
    variables: {
      ...variables,
      first: pageSize,
      offset: pageSize * page,
      orderBy: orderByStr,
      ...filterVariables,
      ...actionsFilters,
      ...headerFilters,
    },
    onCompleted,
    onError,
  });

  const rows = useMemo(
    () => (data ? Object.values(data)[0]?.edges?.map((edge) => edge.node) : []),
    [data],
  );
  const rowCount = useMemo(
    () => (data ? Object.values(data)[0]?.totalCount : 0),
    [data],
  );

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, []);

  const handleSortModelChange = useCallback((newSortModel) => {
    if (newSortModel && newSortModel.length > 0) {
      setSortModel(newSortModel);
    }
  }, []);

  const handleFilterModelChange = useCallback((newFilterModel) => {
    setFilterModel(newFilterModel);
  }, []);

  return (
    <Stack direction="column" spacing={2} p={3} flexGrow={1} bgcolor="#FFFFFF">
      <Header {...headerProps} setHeaderFilters={setHeaderFilters} />
      {Summary && <Summary {...summaryProps} />}
      <FingoStack>
        <RefreshIconButton refetch={refetch} loading={loading} />
        {Actions && (
          <Actions {...actionsProps} setActionsFilters={setActionsFilters} />
        )}
      </FingoStack>
      {Body && <Body {...bodyProps} />}
      <Box id={`${id}-table-box`} sx={{ width: '100%', flexGrow: 1, minHeight: 50 }}>
        <Table
          // required props
          rows={rows}
          columns={tableProps.columns}
          // server props
          loading={loading}
          // sorting props
          sortingMode="server"
          sortingOrder={['asc', 'desc']}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          // pagination props
          pagination
          minHeight={500}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          // filter props
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          components={{
            NoRowsOverlay: DataGridNoRowsOverlay,
          }}
          componentsProps={
            {
              noRowsOverlay: { Message: tableProps.noRowsMessage },
            }
          }
          {...tableProps}
        />
      </Box>
    </Stack>
  );
};

FingoMainViewV2.propTypes = {
  id: PropTypes.string.isRequired,
  apolloQuery: PropTypes.shape({
    query: PropTypes.object.isRequired,
    variables: PropTypes.object,
    onCompleted: PropTypes.func,
    onError: PropTypes.func,
    orderBy: PropTypes.object,
  }).isRequired,
  slots: PropTypes.shape({
    header: PropTypes.elementType,
    summary: PropTypes.elementType,
    actions: PropTypes.elementType,
    table: PropTypes.elementType,
  }),
  slotProps: PropTypes.shape({
    headerProps: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    summaryProps: PropTypes.object,
    actionsProps: PropTypes.object,
    bodyProps: PropTypes.object,
    tableProps: PropTypes.shape({
      columns: PropTypes.array.isRequired,
      noRowsMessage: PropTypes.element,
    }).isRequired,
  }).isRequired,
};

FingoMainViewV2.defaultProps = {
  slots: {
    header: BasicHeader,
    summary: null,
    actions: null,
    table: DataGrid,
  },
};

export default FingoMainViewV2;
