import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Stack } from '@mui/system';
import _ from 'underscore';
import moment from 'moment';
import React from 'react';
import { actionTypeToSpanish } from '../../helpers';
import { AvatarCell } from '../dataGridCells';

const ActionListItem = ({ action, documentsType }) => {
  const { contacts } = action;
  return (
    <Stack>
      <Grid
        container
        direction="row"
        flexWrap="nowrap"
        alignItems="center"
        sx={{ width: 'fit-content', marginBlock: 0.5 }}
      >
        <AvatarCell user={action.author} sx={{ width: 25, height: 25, marginRight: 1 }} />
        <Typography variant="caption" noWrap overflow="visible">
          {moment(action.createdAt).format('DD-MM-YYYY HH:mm[hrs]')}
        </Typography>
      </Grid>
      <Typography variant="subtitle2">
        {actionTypeToSpanish[action.actionType]}
      </Typography>
      {contacts?.map((contact) => (
        <Typography variant="caption" noWrap>
          {`• ${contact.name} | ${contact.position} | ${contact.email}`}
        </Typography>
      ))}

      {Object.entries(_.groupBy(action[documentsType], 'companyName')).map(
        ([key, value]) => (
          <>
            {documentsType === 'invoices' ? (
              <Typography sx={{ display: 'contents' }}>• {value.length > 1 ? 'Folios' : 'Folio'} </Typography>
            ) : (
              <Typography sx={{ display: 'contents' }}>• {value.length > 1 ? 'Órdenes de compra' : 'Orden de compra'} </Typography>
            )}
            {value.map((v) => (
              <>
                <Typography sx={{ display: 'contents', fontWeight: 550 }}>{documentsType === 'invoices' ? v.folio : v.orderNumber}, </Typography>
              </>
            ))}
            <>
              <Typography sx={{ display: 'contents' }}>de la compañía </Typography>
              <Typography sx={{ display: 'contents', fontWeight: 550 }}>{key}</Typography>
              <br />
            </>
          </>
        ),
      )}
      <Typography variant="body1" sx={{ marginBlock: 0.5 }}>
        {action.comment}
      </Typography>
    </Stack>
  );
};

ActionListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.object.isRequired,
  documentsType: PropTypes.string.isRequired,
};

export default ActionListItem;
