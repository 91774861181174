import React from 'react';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { styled } from '@mui/styles';

const FingoLink = styled(
  React.forwardRef((props, ref) => (
    <MuiLink component={Link} ref={ref} {...props} />
  )),
)(() => {});

export default FingoLink;
