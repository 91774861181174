import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

const NavbarLink = ({ id, title, to, onClick }) => {
  const theme = useTheme();
  const activeStyle = {
    color: theme.palette.primary.main,
    fontWeight: '500',
  };
  const props = {
    id: { id },
    sx: {
      '&:hover': {
        color: 'primary.main',
        textDecoration: 'none',
      },
      textDecoration: 'none',
    },
    variant: 'h2',
    color: 'text.main',

  };
  return (
    <Link
      {...props}
      to={to}
      activeStyle={activeStyle}
      component={NavLink}
      onClick={onClick}
    >
      {title}
    </Link>
  );
};

NavbarLink.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

NavbarLink.defaultProps = {
  to: '/login',
  onClick: () => {},
};

export default NavbarLink;
