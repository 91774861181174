import React from 'react';
import PropTypes from 'prop-types';
import { ArrayOfId, MasterEntityType } from '../../propTypes';
import ContactsFormGrid from '../contacts/ContactsFormGrid';
import { CONTACT_COLUMNS } from '../../constants/contact-columns';

const ContactList = ({
  masterEntity,
  contactType,
  showAllContactTypes,
  setSelectedContactIds,
  selectedContactIds,
  columns,
  checkbox,
}) => (
  <ContactsFormGrid
    contactType={contactType}
    masterEntity={masterEntity}
    checkbox={checkbox}
    showAllContactTypes={showAllContactTypes}
    selectedContacts={selectedContactIds}
    setSelectedContact={setSelectedContactIds}
    columns={columns}
  />
);

ContactList.propTypes = {
  setSelectedContactIds: PropTypes.func.isRequired,
  contactType: PropTypes.string,
  masterEntity: MasterEntityType.isRequired,
  showAllContactTypes: PropTypes.bool,
  selectedContactIds: ArrayOfId.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  checkbox: PropTypes.bool,
};

ContactList.defaultProps = {
  showAllContactTypes: false,
  contactType: null,
  columns: CONTACT_COLUMNS,
  checkbox: true,
};

export default ContactList;
